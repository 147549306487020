import './style.css'
import Player from '../Player'
import React, { useState, useEffect } from 'react'
import Personalize from '../Personalize'
import { Collapse } from 'react-collapse'

function PersonalizeLanding (props) {
  const [personalize, setPersonalize] = useState(false)
  const [isZoom, setIsZoom] = useState(false)
  const [country, setCountry] = useState('USA')
  const [priceUSD, setPriceUSD] = useState('70,000')
  const [priceEuro, setPriceEuro] = useState('65,000')

  const theme = {
    collapse: 'ReactCollapse--collapse',
    content: 'ReactCollapse--content'
  }
  useEffect(() => {
    // apply2DSpin({ attrName: 'Rotation', direction: 1 })(window.player)
  }, [])
  function setPrice(){
    console.log(window.configurator.getConfiguration()['Engraving'].length)
    if (window.configurator.getConfiguration()['Engraving'] != '') {
      setPriceUSD('75,000')
      setPriceEuro('70,000')
    } else{
      setPriceUSD('70,000')
      setPriceEuro('65,000')
    }
  }
  return (
    <div>
      <div className='logged-in'>
        <div className='left'>
          <div className='left-content'>
            <h1>
              {props.locale === 'US'
                ? 'MAKE YOUR OWN HENNESSY 8 -'
                : 'FAITES VOTRE PROPRE HENNESSY 8 -'}{' '}
              {props.selected.toUpperCase()}
            </h1>
            <h3>{props.locale === 'US' ? '$' + priceUSD : '€' + priceEuro}</h3>
            <h4 className='mh-title'>
              {props.locale === 'US'
                ? 'PRIVILEGE OF PERSONALISATION'
                : 'PRIVILÈGE DE PERSONNALISATION'}
            </h4>
            <p>
              {props.locale === 'US'
                ? 'Each of the 250 decanters can be personalized by engraving message on the last of the eight rings. Add your name to the exceptional.'
                : "Chacune des 250 carafes peut être personnalisée en gravant un message sur le dernier des huit anneaux. Ajoutez votre nom à l'exceptionnel."}
            </p>

            <a
              className='circle-title'
              href='#'
              onClick={() =>
                personalize ? setPersonalize(false) : setPersonalize(true)
              }
            >
              <span className='badge' href='/'>
                {personalize ? '▲' : '▼'}
              </span>
              <span style={{ marginLeft: '10px' }}>
                {props.locale === 'US' ? 'PERSONALISE' : 'PERSONNALISER'}
              </span>
            </a>

            <Collapse
              isOpened={personalize}
              theme={theme}
              initialStyle={{ height: '120px', overflow: 'hidden' }}
            >
              <div>
                <Personalize locale={props.locale} onChange={setPrice} />
              </div>
            </Collapse>
          </div>
        </div>
        <div className='right'>
          <div className='right-content'>
            <Player selected={props.selected} num={props.num}/>
            <div className='right-buttons'>
              <img
                src='https://solutions-engineering.s3.amazonaws.com/mh/360.png'
                style={{ height: '30px', width: '30px' }}
                onClick={() => {
                  console.log('click')
                  let el = document.getElementsByClassName('canvas___BrMgq')[0]

                  el.style.pointerEvents = 'auto'
                  window.configurator.setConfiguration({ Camera: '360' })
                }}
                className='player-buttons'
              />
              <img
                src='https://solutions-engineering.s3.amazonaws.com/mh/detail2.png'
                style={{ height: '30px', width: '30px' }}
                onClick={() => {
                  console.log('click')
                  window.configurator.setConfiguration({
                    Camera: 'detail1',
                    Rotation: 0
                  })
                  setIsZoom(true)
                  let el = document.getElementsByClassName('canvas___BrMgq')[0]
                  console.log(el)
                  el.style.pointerEvents = 'none'
                }}
                className='player-buttons'
              />
              <img
                src='https://solutions-engineering.s3.amazonaws.com/mh/detail1.png'
                onClick={() => {
                  console.log('click')
                  window.configurator.setConfiguration({
                    Camera: 'detail2',
                    Rotation: 0
                  })
                  setIsZoom(true)
                  let el = document.getElementsByClassName('canvas___BrMgq')[0]

                  el.style.pointerEvents = 'none'
                }}
                className='player-buttons'
              />
              {/* <p className="right-btn">x PERSONALISE</p>
              <p className="right-btn">x DETAILS</p>
              <p className="mh-title right-btn">x ADD</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className='logout-button'>
        <button
          onClick={() => {
            localStorage.removeItem('user')
            window.location.reload()
          }}
          className='log-out-btn'
        >
          Log out
        </button>
      </div>
    </div>
  )
}

export default PersonalizeLanding
