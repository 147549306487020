import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState, useEffect } from 'react'
import PersonalizeLanding from '../PersonalizeLanding'
import Landing from '../Landing'
import './style.css'
const firebaseConfig = {
  apiKey: 'AIzaSyCUcvxMZdTLTqAodNChIGVBLg-MzO7-aFk',
  authDomain: 'mh-demo-6152f.firebaseapp.com',
  projectId: 'mh-demo-6152f',
  storageBucket: 'mh-demo-6152f.appspot.com',
  messagingSenderId: '174987082864',
  appId: '1:174987082864:web:3bb6a522610050b1b908b5'
}

function Login () {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [auth, setAuth] = useState()
  const [app, setApp] = useState()
  const [error, setError] = useState(null)

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    setApp(initializeApp(firebaseConfig))
    setAuth(getAuth())
    localStorage.getItem('user') ? setIsLoggedIn(true) : setIsLoggedIn(false)
  }, [auth])

  const onChange = e => {
    e.target.id === 'name'
      ? setUsername(e.target.value)
      : setPassword(e.target.value)
  }
  const logIn = e => {
    e.preventDefault()

    signInWithEmailAndPassword(auth, username, password)
      .then(userCredential => {
        // Signed in
        const user = userCredential.user
        setIsLoggedIn(true)
        localStorage.setItem('user', JSON.stringify(user))
      })
      .catch(error => {
        // const errorCode = error.code
        // const errorMessage = error.message
        setError('Invalid email/password combo. Please try again.')
        console.log(error)
      })
  }
  return (
    <div className='login-container'>
      {isLoggedIn ? (
        <div>
          <div>
            <Landing />

            {/* <PersonalizeLanding /> */}
          </div>
        </div>
      ) : (
        <div>
          <center>
            <center>
              <h1>Welcome</h1>
              <p>Please log in to continue to the experience. </p>
            </center>
            <p className='error-message'>{error ? error : null}</p>
            <form
              onSubmit={() => {
                return false
              }}
            >
              <input
                type='text'
                id='name'
                name='name'
                placeholder='Username'
                className='auth-field'
                value={username}
                onChange={onChange}
              />
              <br />
              <input
                type='password'
                id='password'
                name='password'
                placeholder='Password'
                className='auth-field'
                value={password}
                onChange={onChange}
              />
              <br />
              <button onClick={logIn}>Login</button>
            </form>
          </center>
        </div>
      )}
    </div>
  )
}

export default Login
