import React, { useState, useEffect } from 'react'
import './style.css'
import { apply2DSpin } from '../../Helpers/apply2DSpin'
function Player (props) {
  useEffect(() => {
    window
      .threekitPlayer({
        authToken: '58f4b6d0-618c-4109-a84e-f18e011b8249',
        el: document.getElementById('player'),
        assetId: '9d177bff-6fb6-4ba4-ae3f-b68797b07cdf',
        showLoadingThumbnail: false,
        showConfigurator: false,
        showAR: false
      })
      .then(async function (player) {
        window.player = player
        window.configurator = await player.getConfigurator()
        await window.configurator.prefetchAttributes(['Rotation'])

        window.configurator.setConfiguration({
          'H8 Bottle Style': props.selected == "L'Antenne" ? "LAntenne" : "Eaux Claires"
        })
        window.configurator.setConfiguration({
          'Engraving Number': `${props.num}`
        })

     
          apply2DSpin({ attrName: 'Rotation', direction: 1 })(player)
      })
  }, [])

  return (
    <div className='player-container'>
      <div id='player'></div>
    </div>
  )
}

export default Player
