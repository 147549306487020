import React, { useState, useEffect } from 'react'
import './style.css'

function Personalize (props) {
  useEffect(() => {

  }, [])

  const onChange = (e) => {
    console.log(props)
    window.configurator.setConfiguration({ Engraving: e.target.value }).then(()=>{
      props.onChange()

    })
  }
  return (
    <div className='personalize'>
      <h4 className='mh-title'>{props.locale === 'US' ? "ENGRAVING" : "GRAVURE"}</h4>
      <input
        type='text'
        id='fname'
        name='fname'
        placeholder={props.locale === 'US' ? 'Name' : 'Nom'}
        className='mh-title'
        onChange={e => onChange(e)}
        maxLength='10'
        style={{ textTransform: 'uppercase' }}
      />

    </div>
  )
}

export default Personalize
